:root{
    --new-indicator-bg: #e7707d;
    --new-body-bg: #f4959d;
    --processing-indicator-bg: #e296bf;
    --processing-body-bg: #eab4d1;
    --confirmed-indicator-bg: #e8a765;
    --confirmed-body-bg: #eec092;
    --not-confirmed-indicator-bg: #e2b8a9;
    --not-confirmed-body-bg: #eaccc2;
    --cook-indicator-bg: #28cacc;
    --cook-body-bg: #88e2e2;
    --cooked-indicator-bg: #83d160;
    --cooked-body-bg: #bbe8a4;
    --delivery-indicator-bg: #ffce45;
    --delivery-body-bg: #fff58e;
    --deferred-indicator-bg: #7d6dad;
    --deferred-body-bg: #a398c4;
    --written-off-indicator-bg: #959fac;
    --written-off-body-bg: #b9c5d2;
    --cancel-indicator-bg: #757d86;
    --cancel-body-bg: #959fac;
    --done-indicator-bg: #e0dede;
    --done-body-bg: #f1f1f1;
}

.order_table_mobile_main{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.order_table_mobile_head{
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 450px){
        padding-top: 5px;
        justify-content: start;
        flex-direction: column;
        gap: 5px;
    }

    @media (max-width: 350px){
        font-size: 0.8rem;
    }
}

.order_table_mobile_row{
    border-top: 1px solid #f2f3f8;
    display: flex;
}

.order_table_head{
    color: #000;                      
    font-size: 1.1rem;     
    font-weight: 300;        
    padding-left: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;

    @media (max-width: 1200px){
        font-size: 0.9rem !important;
    }

    @media (max-width: 350px){
        gap: 3px;
    }
}

.kt-datatable__body{
    &.order_table{
        background-color: #00000000;
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important;
    }    
}

.kt-datatable__cell{
    &.order_table{
        border-right: 1px dotted #f2f3f8;
        padding: 10px 5px !important;
        
        @media (max-width: 1250px){
            padding: 5px !important;
        }

        @media (max-width: 1200px){
            font-size: 0.8rem !important;
        }

        @media (max-width: 1000px){
            padding: 0px !important;
        }

        &.positions{
            min-width: 250px;
            width: 300px;

            @media (max-width: 1400px){
                width: 250px;
            }

            @media (max-width: 1100px){
                min-width: 200px;
                width: 210px;
            }

            &.openClient{
                @media (max-width: 1350px){
                    font-size: 0.8rem !important;
                    min-width: 200px;
                    width: 210px;
                }    
            }

            @media (max-width: 1000px){
                padding-left: 5px !important;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
            }


            @media (max-width: 950px){
                min-width: 190px;
                width: 190px;
            }
        }

        &.total_price{
            width: 60px;
            min-width: 60px;

            &.openClient{
                @media (max-width: 1350px){
                    font-size: 0.8rem !important;
                    min-width: 40px;
                    width: 40px;
                }    
            }

            @media (max-width: 1200px){
                min-width: 40px;
                width: 40px;
            }    
        }

        &.payment{
            min-width: 90px;
            width: 160px;

            
            @media (max-width: 1600px){
                width: 90px;
            }

            &.openClient{
                @media (max-width: 1350px){
                    font-size: 0.8rem !important;
                    min-width: 70px;
                    width: 70px;
                }    
            }

            @media (max-width: 1200px){
                min-width: 70px;
                width: 70px;
            }

            @media (max-width: 1000px){
                padding-left: 5px !important;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
            }
        }

        &.point_time{
            width: 70px;
            min-width: 70px;
        }

        &.select_point{
            min-width: 240px;
            width: 240px;

            &.openClient{
                min-width: 180px;
                width: 180px;
            }

            @media (max-width: 1200px){
                min-width: 200px;
                width: 200px;                
            }

            &.openClient{
                @media (max-width: 1350px){
                font-size: 0.8rem !important;
                min-width: 150px;
                width: 150px;
                }

                @media (max-width: 1100px){
                    min-width: 130px;
                    width: 130px;
                }
            }

            @media (max-width: 1000px){
                    padding: 5px !important;                
            }
        }

        &.comment{
            min-width: 350px;

            &.openClient{
                min-width: 200px;
            }

            @media (max-width: 1600px){
                min-width: 200px;

                &.openClient{
                    min-width: 150px;                    
                }
            }

            &.openClient{
                @media (max-width: 1350px){
                    font-size: 0.8rem !important;
                    min-width: 140px;
                }    

                @media (max-width: 1050px){
                    min-width: 110px;
                    width: 110px;
                }
            }

            @media (max-width: 1250px){
                min-width: 140px;
            }

            @media (max-width: 1000px){
                padding: 5px !important;                
            }        
        }

        &.phone{
            width: 140px;

            @media (max-width: 1250px){
                width: 110px;
            }

            &.openClient{
                @media (max-width: 1350px){
                    font-size: 0.8rem !important;
                    min-width: 110px;
                }    
            }

            @media (max-width: 1000px){
                padding-left: 5px !important;
            }
        }

        &.action{
            width: 30px;
            @media (max-width: 1000px){
                padding: 5px !important;
            }
        }

        &:last-of-type{
            border: none;
        }
    }
}

.order_table_mobile{
    border-right: 1px dotted #f2f3f8;
    padding: 10px 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &.positions{
        flex-direction: column;
        justify-content: start;
        align-items: start;
        min-width: 190px;
        width: 100%;
    }

    &.total_price{
        width: 60px;
        min-width: 60px;

        @media (max-width: 350px){
            width: 40px;
            min-width: 40px;
        }
    }

    &.point_time{
        width: 100%;
        text-align: center;
        min-width: 70px;
    }

    &.select_point{
        min-width: 350px;

        @media (max-width: 450px){
            min-width: 250px;
        }

        @media (max-width: 350px){
            min-width: 180px;
        }
    }

    &.comment{
        width: 100%;
        flex-wrap: wrap;
    }

    &:last-of-type{
        border: none;
    }

    &.phone_delete{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}

.order_table_selector{
    width: 100%;
    font-weight: 300;

    @media (max-width: 1200px){
        font-size: 0.8rem;
    }

    @media (max-width: 450px){
        font-size: 1rem;
    }

    @media (max-width: 350px){
        font-size: 0.8rem;
    }
}

.order_table_status_select__value-container{
    min-height: 28px !important;
    max-height: 28px;
}

.order_table_status_select__indicators-container{
    min-height: 28px !important;
    max-height: 28px;
}

.order_table_status_select__indicator{
    min-height: 28px !important;
    max-height: 28px;
    padding: 4px !important;
}

.order_table_status_select__input{
    min-height: none;
    max-height: 28px;
    padding: 0;
}

.order_table_status_select__control{
    min-height: 28px !important;
    max-height: 28px;
}

.kt-indicator{
    width: 0.7rem;    
    background-color: #000;
    &.top{
        border-top-left-radius: 4px !important;
    }
    
    &.bottom{
        border-bottom-left-radius: 4px !important;
    }

    &.all{
        border-bottom-left-radius: 4px !important;
        border-top-left-radius: 4px !important;
    }

    &.new{
        background-color: var(--new-indicator-bg);
    }
    &.processing{
        background-color: var(--processing-indicator-bg);
    }
    &.confirmed{
        background-color: var(--confirmed-indicator-bg);      
    }
    &.not-confirmed{
        background-color: var(--not-confirmed-indicator-bg);
    }
    &.cook{
        background-color: var(--cook-indicator-bg);
    }
    &.cooked{
        background-color: var(--cooked-indicator-bg);
    }
    &.delivery{
        background-color: var(--delivery-indicator-bg);
    }
    &.deferred{
        background-color: var(--deferred-indicator-bg);
    }
    &.written-off{
        background-color: var(--written-off-indicator-bg);
    }
    &.cancel{
        background-color: var(--cancel-indicator-bg);
    }
    &.done{
        background-color: var(--done-indicator-bg);
    }
}

.kt-datatable__row{
    &.order_table{
        color: #000 !important;
        background-color: #fff;
        border-radius: 4px !important;
        &:hover{
            background-color: color-mix(in srgb-linear, #fff, #000 10%);
        }
        &.new{
            background-color: var(--new-body-bg);            
            &:hover{
                background-color: color-mix(in srgb-linear, var(--new-body-bg), #000 10%);
            }
        }
        &.processing{
            background-color: var(--processing-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--processing-body-bg), #000 10%);
            }
        }
        &.confirmed{
            background-color: var(--confirmed-body-bg);     
            &:hover{
                background-color: color-mix(in srgb-linear, var(--confirmed-body-bg), #000 10%);
            } 
        }
        &.not-confirmed{
            background-color: var(--not-confirmed-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--not-confirmed-body-bg), #000 10%);
            } 
        }
        &.cook{
            background-color: var(--cook-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--cook-body-bg), #000 10%);
            } 
        }
        &.cooked{
            background-color: var(--cooked-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--cooked-body-bg), #000 10%);
            } 
        }
        &.delivery{
            background-color: var(--delivery-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--delivery-body-bg), #000 10%);
            } 
        }
        &.deferred{
            background-color: var(--deferred-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--deferred-body-bg), #000 10%);
            } 
        }
        &.written-off{
            background-color: var(--written-off-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--written-off-body-bg), #000 10%);
            } 
        }
        &.cancel{
            background-color: var(--cancel-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--cancel-body-bg), #000 10%);
            } 
        }
        &.done{
            background-color: var(--done-body-bg);
            &:hover{
                background-color: color-mix(in srgb-linear, var(--done-body-bg), #000 10%);
            } 
        }
    }
}

.button_order_table{
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color: #000;    
    border-radius: 2px;
    align-self: center;
    font-size: 1rem;
    transition: all 0.15s ease-in-out;
    background-color: #00000000;
    &:hover{
        color: #5d78ff;
        background-color: color-mix(in srgb-linear, #00000000, #ffffff 60%);
    }
    &:active{
        background-color: color-mix(in srgb-linear, #00000000, #ffffff 20%);
    }
}

.order_table-order-card{
    min-width: 310px;
    padding: 10px 5px;

    @media (max-width: 900px){
        width: 100%;
    }
}

.order_table-order-card__name{
    display: flex;
    width: 210px;
    flex-wrap: wrap;
    color: #000;

    @media (max-width: 1400px){
        width: 150px;
    }   

    @media (max-width: 1100px){
        width: 120px;
    }

    &.openClient{
        @media (max-width: 1350px){
            width: 120px;
        }
    }

    @media (max-width: 950px){
        width: 110px;
    }

    @media (max-width: 900px){
        min-width: 170px;
        width: 100%;
    }

    @media (max-width: 450px){
        min-width: 110px;
        width: 100%;
    }
}

.order_table_button-show-details{
    cursor: pointer;
    display: flex;
    border: none;
    outline: none;
    color: #000;    
    border-radius: 2px;
    align-self: flex-start;
    transition: all 0.15s ease-in-out;
    background-color: #00000000;
    &:hover{
        color: #5d78ff;
        background-color: color-mix(in srgb-linear, #00000000, #ffffff 60%);
    }
    &:active{
        background-color: color-mix(in srgb-linear, #00000000, #ffffff 20%);
    }
}

.order_table_arrow-show-details{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    color: #000;    
    border-radius: 2px;
    align-self: center;
    transition: all 0.15s ease-in-out;
    background-color: #00000000;
    &:hover{
        color: #5d78ff;
        background-color: color-mix(in srgb-linear, #00000000, #ffffff 60%);
    }
    &:active{
        color: #2a4dfa;
        background-color: color-mix(in srgb-linear, #00000000, #ffffff 20%);
    }
}

.kt-menu__ver-arrow.la.la-angle-down{
    transition: all 0.15s ease-in-out;
    &.Showed{
        transform: rotate(180deg);
    }
}

.flaticon2-copy{
    &.order_table{
        &::before{
            display: block;
            width: 13px;
            height: 13px;
        }
    }
}

.flaticon2-trash{
    &.order_table{
        &::before{
            display: block;
            width: 13px;
            height: 13px;
        }
    }
}

.indicator_deliveryMethod{
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0rem 0.25rem;
    outline: 1px solid #005fcf;
    color: #005fcf;
    border-radius: 2px;

    @media (max-width: 1200px){
        font-size: 0.7rem;
    }
}

.kt-datatable__pager {
    &.kt-datatable--paging-loaded{ 
        &.order_table{
            height: 100%;
            background-color: #fff;
            padding-bottom: 25px;
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }
}

.categoty_image{
    display: block;
    width: 30px;
    margin-right: 10px;
    
    @media (max-width: 1230px){
        display: none;
    }
}

.order_table.payment.select{    
    position: absolute;
    width: 150px;
}

.dropdown_body_changeLog{
    display: inline-block;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    margin-top: 10px;
    max-height: 310px;
    overflow-y: auto;
    width: auto;
    border: 1px solid #e7e8ef;
    &.up{
        bottom: calc(100% + 30px);
    }
}

.dropdown_row_changeLog{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #e7e8ef;
    gap: 10px;
    white-space: nowrap;
    &:last-of-type{
        border-bottom: none;
    }
}

.status_circle{
    opacity: 0.8;
    width: 13px;
    height: 13px;
    border-radius: 50% !important;
    border-radius: 50%;
    background-color: #000;
    &.new{
        background-color: var(--new-indicator-bg);
    }
    &.processing{
        background-color: var(--processing-indicator-bg);
    }
    &.confirmed{
        background-color: var(--confirmed-indicator-bg);      
    }
    &.not-confirmed{
        background-color: var(--not-confirmed-indicator-bg);
    }
    &.cook{
        background-color: var(--cook-indicator-bg);
    }
    &.cooked{
        background-color: var(--cooked-indicator-bg);
    }
    &.delivery{
        background-color: var(--delivery-indicator-bg);
    }
    &.deferred{
        background-color: var(--deferred-indicator-bg);
    }
    &.written-off{
        background-color: var(--written-off-indicator-bg);
    }
    &.cancel{
        background-color: var(--cancel-indicator-bg);
    }
    &.done{
        background-color: var(--done-indicator-bg);
    }
}

.search_type_button{
    height: 2rem;
    width: 30%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 0.15rem 0.25rem;
    color: #595d6e;    
    transition: all 0.15s ease-in-out;
    background-color: #f1f1f5;
    box-shadow: 0px 1px 0px #e0e0e4;
    &:hover{
        background-color: #e0e0e4;
        box-shadow: 0px 1px 0px #d0d0d4;
    }
    &:active{
        background-color: #d0d0d4;
    }
    &.right{
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
    &.left{        
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
    &.actived{
        cursor: auto;
        color: #f7f8fa;
        background-color: #5d78ff;
        box-shadow: 0px 1px 0px #5871f1;
    }
}