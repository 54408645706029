@media (min-width: 1025px) {
    .kt-app .kt-app__aside {
        width: 400px;
    }

    .kt-aside_blackout {
        display: none;        
    }

    .dropdown-menu{
        &.hidden_desktop{
            display: none !important;
        }
    }

    .sidebar{
        width: 60px;
        z-index: 1005;    

        &:hover{
            width: 270px;
        }
    }   
}

@media (max-width: 1850px) {
    .neworder_container{
        &.detail{
            min-width: 270px;
        }
    }
}

@media (max-width: 1620px) {
    .good_hot_circle, .good_vegan_circle {
        margin-bottom: 50px;
        margin-left: 115px;
    }

    .neworder_good_button{
        width: 125px;
        height: 60px;
    }
}

@media (max-width: 1570px) {
    .kt-container.neworder {
        padding: 0px !important;
    }

    .neworder_container{
        padding: 5px;
    
        &.categories{
            margin-right: 5px;
        }
    
        &.detail{
            margin-right: 5px;
        }
    
        &.order_position_list{
            min-width: 455px;
            width: 455px;
            margin-right: 5px;
        }
    }

    .position_list_row{
        min-width: 425px;        
    }
}

@media (max-width: 1500px) {
    .neworder_container{
        &.categories{
            width: 155px;
            min-width: 155px;
        }
    }

    .kt-nav .kt-nav__item > .kt-nav__link.neworder{
        padding: 5px 10px;
    }
}

@media (max-width: 1480px) {
    .kt-app__aside{
        width: 300px !important;
    }
    
    .col-xl-4{
        width: 100% !important;
        max-width: none;
        flex: 1;        
    }
    
    .col-xl-8{
        width: 100% !important;
        max-width: none;
        flex: 1;        
    }
}

@media (max-width: 1460px) {
    .user_list-info{
        width: 350px;
    
        &.point{
            width: 250px;       
        }
    
        &.status{
            width: 90px;
        }
    }
}

@media (max-width: 1430px) {
    .neworder_container{
        &.categories{
            width: 145px;
            min-width: 145px;
        }
    }

    .kt-nav .kt-nav__item > .kt-nav__link.neworder{
        padding: 5px;
    }
}

@media (max-width: 1420px) {
    .neworder_good_button{
        width: 110px;
    }

    .neworder_good_button_text{
        font-size: 0.7rem;
    
        &.price{
            font-size: 0.8rem;
        }
    }
    
    .good_hot_circle, .good_vegan_circle {
        margin-left: 100px;
    }
}

@media (max-width: 1400px){
    .neworder_good_head{
        font-size: 0.8rem;
    }
}


@media (max-width: 1380px){
    .kt-app__aside{
        width: 260px !important;
    }
}

@media (max-width: 1370px){
    .select_detail_body{    
        font-size: 0.8rem;
    }

    .neworder_container{
        &.detail{
            min-width: 220px;
        }
    }

    .form-control{
        &.neworder{
            font-size: 0.8rem;
            height: auto;
            padding: 5px;
        }
    }
    
    .neworder_detail_information{
        font-size: 0.8rem;
    }
    
    .neworder_detail_deliverymethod_button{
        font-size: 0.8rem;
        padding: 0.1rem 0.2rem;
        width: 50px;
        height: 1.5rem;
    }
    
    .neworder_detail_input_div{
        font-size: 0.8rem;
    }

    .order_position_list_footer_button{
        &.payment{
            font-size: 0.9rem;
        }
    }
}

@media (max-width: 1330px){
    .client_table-info{
        width: 110px;
    
        &.status{
            width: 90px;
        }
    }

    &.order_position_list{
        min-width: 390px;
        width: 390px;
    }

    .neworder_good_button_container{
        gap: 5px; 
    }
    
    .neworder_comment_point{
        font-size: 0.9rem;
        height: 1.7rem;
        min-width: 2rem;
    }

    .position_list_row{
        min-width: 360px;
        gap: 5px;
    }
    
    .position_list_position{
        font-size: 0.9rem;
    
        &.head_name{
            width: 45%;
        }
        
        &.container_name{
            width: 45%;
        }
    
        .row_name{
            gap: 5px;
        }
    }

    .position_list_position{
        font-size: 0.9rem;
    
        &.head_name{
            width: 45%;
        }
        
        &.container_name{
            width: 45%;
        }
    
        .row_name{
            gap: 5px;
        }
    }

    .popup_additives_buttons_div{
        grid-template-columns: repeat(3, 1fr); 
    }

    .neworder_good_main{
        min-width: 240px;
    }
}

@media (max-width: 1260px) {
    .neworder_container{            
        &.categories{ 
            width: 115px;
            min-width: 115px;
            padding-top: 0px;
        }
    
        &.order_position_list{
            min-width: 370px;
            width: 370px;            
        }

        .kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-text.neworder{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-wrap: wrap;            
        }

        .position_list_row{
            min-width: 340px;            
        }
    }

    .kt-nav .kt-nav__item > .kt-nav__link.neworder{
        margin-top: 5px;
        border-radius: 4px;
        background-color: #f1f1f5;
        box-shadow: 0px 2px 0px #e0e0e4;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100px;
        height: 50px;        
    }
}

@media (max-width: 1245px) {
    .user_list-info{
        width: 300px;
    
        &.point{
            width: 200px;
        }
    
        &.role{
            width: 200px;
        }
    }
}


@media (max-width: 1230px) {
    .kt-datatable__cell{
        &.good_id{
            padding: 5px !important;
            padding-left: 10px !important;            
        }
    
        &.good_preview{
            padding: 5px !important;
        }
    
        &.good_name{
            padding: 5px !important;
        }
    
        &.good_info{
            padding: 5px !important;
        }
    
        &.table_action{
            width: 135px;
            padding: 5px !important;
            padding-right: 10px !important;
        }
    
        &.promocodes_action{
            padding: 5px !important;
            padding-right: 10px !important;
        }
    
        &.table_citites_action{
            padding: 5px !important;
            padding-right: 10px !important;            
        }
    
        &.table_filials_action{
            padding: 10px 5px !important;
            padding-right: 10px !important;
        }

        &.table_filials_action{
            padding: 10px 5px !important;
            padding-right: 10px !important;            
        }
    }

    .kt-todo .kt-todo__aside.goods {
        width: 165px;
        padding: 10px;        
    }

    .kt-todo .kt-todo__aside .kt-todo__nav .kt-nav .kt-nav__item .kt-nav__link.goods{
        padding: 0.6rem 10px;
    }

    .kt-todo .kt-todo__aside.statistic {
        width: 165px;
        padding: 10px;
    }

    .order_table.payment.select{    
        width: 100px;
    }
}

@media (max-width: 1200px) {
    .neworder_good_button{
        width: 100px;
        padding: 2px;        
    }
    
    .good_hot_circle, .good_vegan_circle {
        margin-left: 90px;        
    }    

    .neworder_good_main{
        min-width: 230px;
    }    
}

@media (max-width: 1175px) {
    .kt-datatable__cell{   
        &.table_filials_point{
            padding: 10px 5px !important;
            min-width: 150px;
        }
    
        &.table_filials_delivery_time{
            padding: 10px 5px !important;
        }
    
        &.table_filials_point_status{
            padding: 10px 5px !important;
        }
    
        &.table_filials_stop_list{
            padding: 10px 5px !important;
        }
    
        &.table_filials_buttons{
            padding: 10px 5px !important;
            min-width: 85px;
        }
    }
}

@media (max-width: 1160px) {    
    .position_list_row{
        min-width: 280px;
    }
}

@media (max-width: 1150px) {
    .client_table-info{
        font-size: 0.9rem;
        width: 90px;
    
        &.action{       
            font-size: 0.9rem;
        }
    
        &.status{
            font-size: 0.9rem;
        }
    }

    .user_list-info{
        width: 200px;
        font-size: 0.9rem;
    
        &.point{
            font-size: 0.9rem;  
        }
    
        &.role{
            width: 150px;
            font-size: 0.9rem;
        }
    
        &.status{
            font-size: 0.9rem;
        }
    
        &.action{
            font-size: 0.9rem;
        }
    }
    
    .kt-datatable__cell{        
        &.news{
            width: 250px;
        }
    }
}

@media (max-width: 1100px) {
    .kt-todo .kt-todo__aside.goods {
        width: 135px;
        padding: 10px;
    }  

    .kt-datatable__cell{          
        &.yookassa_table_name{
            width: 180px;            
        }
    }

    .kt-todo .kt-todo__aside.statistic {
        width: 135px;
        padding: 10px;
    }

    .kt-svg-icon.kt-nav__link-icon.statistic{
        display: none;
    }
    
    .kt-todo .kt-todo__aside .kt-todo__nav .kt-nav .kt-nav__item .kt-nav__link.statistic{
        padding: 0.6rem 10px;
    }    

    .kt-datatable__cell{   
        &.slider_title{
            width: 250px;
        }    
    }

    .neworder_container{
        &.detail{
            min-width: 200px;
        }
    }
}

@media (max-width: 1050px) {
    .kt-datatable__cell{   
        &.news{
            width: 200px;
        }
    }
}

@media (max-width: 1024px) {
    .kt-aside.kt-grid__item.kt-grid.kt-grid--desktop.kt-grid--hor-desktop{
        position: fixed;
    }

    .main-wrapper{
        padding-left: 0px;
    }

    .kt-aside__brand {
        display: flex !important;
    }

    .kt-app__aside{
        position: relative !important;
        left: auto;
        width: 100% !important;
        padding: 0px !important;
        overflow: hidden !important;
        background-color: #00000000 !important;
        margin-top: 20px;        
    }

    .kt-grid__item.kt-portlet.kt-todo__aside.goods{
        position: relative;
        width: 100% !important;
        margin-bottom: 3px;
        z-index: 1;
        left: auto;
        top: auto;
        padding: 3px 0px 3px 0px !important;
    }

    .kt-grid.kt-grid--desktop.kt-grid--ver-desktop.kt-todo.goods{
        display: block;
    }
    
    .kt-nav.category{   
        height: 60px;
        margin-right: 0px;
        width: 100%;
        display: flex;
        overflow: hidden;
        overflow-x: auto;        
    }
    
    .kt-nav__link.goods{
        display: flex !important;
        height: 100%;
        width: 100px;
        justify-content: center !important;
        align-items: center !important;
        margin-bottom: 0px;
        border-radius: 0px !important;        
    }
    
    .kt-nav__link-text.goods{
        text-align: center !important;
    }
    
    .kt-nav__item.goods{
        margin-bottom: 5px !important;        
    }
    
    .modal-content{
        margin-top: 55px;
    }

    .kt-grid__item.kt-portlet.kt-inbox__aside.push_message{
        position: relative;
        width: 100% !important;
        margin-bottom: 3px;
        z-index: 1;
        left: auto;
        top: auto;
        padding: 3px 0px 3px 0px !important;        
    }
    
    .kt-inbox__nav.push_message{
        margin-top: 0px !important;
    }
    
    .kt-nav.push_message{
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        height: auto;
        padding: 0px !important;        
    }
    
    .kt-nav__item.push_message{
        margin-bottom: 0px !important;
        min-width: 140px;
        width: 140px;
    }
    
    .kt-grid__item.kt-portlet.kt-todo__aside.statistic{
        position: relative;
        width: 100% !important;
        margin-bottom: 3px;
        z-index: 1;
        left: auto;
        top: auto;
        padding: 3px 0px 3px 0px !important;
    }
    
    .kt-grid.kt-grid--desktop.kt-grid--ver-desktop.kt-todo.statistic{
        display: block;
    }

    .kt-nav__link.statistic{
        display: flex !important;
        height: 100%;
        width: 150px;
        justify-content: center !important;
        align-items: center !important;
        margin-bottom: 0px;
        border-radius: 0px !important;
    }
    
    .kt-nav.statistic{   
        height: 60px;
        margin-right: 0px;
        width: 100%;
        display: flex;
        overflow: hidden;
        overflow-x: auto;
    }
    
    .kt-nav__link-text.statistic{
        text-align: center !important;
    }
    
    .kt-nav__item.statistic{
        margin-bottom: 5px !important;
    }

    .neworder_container{
        height: calc(100vh - 65px);
        margin-top: 5px;
    
        &.goods{
            height: calc(100vh - 90px);
            margin-top: 0px;
        }
    
        &.categories{            
            width: 95px;
            min-width: 95px;
            padding-top: 0px;            
        }     
    }

    .neworder_good_head{
        margin-top: 5px;
    }
    
    .kt-nav .kt-nav__item > .kt-nav__link.neworder{
        width: 80px;
        height: 40px;        
    }

    .kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-text.neworder{
        font-size: 0.8rem;        
    }

    .kt-menu__nav{
        &.scrolled{
            height: 100vh;
            padding-top: 25px;
        }
    }  
}

@media (max-width: 1000px){
    .neworder_good_main{
        min-width: auto;
    }
}

@media (max-width: 930px) {
    .blockPush {
        width: 100%;
    }        

    .position_list_position{
        &.head_name{
            width: calc(45% - 30px);
        }

        &.container_name{
            width: calc(45% - 30px);
        }
    }

    .neworder_container{
        height: calc(100vh - 95px);
        margin-top: 0px;        
    
        &.goods{
            height: calc(100vh - 120px);
            margin-top: 0px;
        }
    
        &.categories{
            margin-right: 3px;
        }

        &.order_position_list{
            min-width: 310px;
            width: 310px;
    }
    
        &.detail{
            min-width: 310px;
            width: 310px;
            margin-right: 3px;
        }
    
        &.order_position_list{
            margin-right: 3px;
        }
    }

    .neworder_good_main{
        min-width: auto;
    }

    .neworder_good_head{
        margin-top: 0px;        
    }
}

@media (max-width: 850px){
    .kt-datatable__pager{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
}

@media (max-width: 830px) {
    .user_list-info{
        width: 150px;
    
        &.point{
            width: 150px;
        }
    
        &.role{
            width: 100px;
        }
    }
}

@media (max-width: 768px){
    .kt-datatable__pager{   
        gap: 0px;
    }
}

@media (max-width: 750px) {
    .kt-widget__subhead.detail_good{
        flex-direction: column;
        gap: 10px;
    }

    .neworder_good_main{
        min-width: 220px;
    }
}

@media (max-width: 640px) {
    .neworder_container{
        &.categories{        
            margin-right: 0px;
            padding: 2px;
            min-width: 90px;
            width: 90px;
        }
    
        &.detail{
            min-width: 310px;
            width: calc(100% - 10px);
            margin-right: 0px;
        }
    
        &.order_position_list{
            min-width: 310px;
            width: calc(100% - 10px);
            margin-right: 0px;
        }
        
        &.goods_mobile{
            min-width: 310px;
            width: auto;
            margin-right: 0px;            
        }
    }

    .neworder_good_main{
        min-width: auto;
        margin-right: 0px;        
    }
}

@media (max-width: 530px) {
    .kt-inbox .kt-inbox__list .kt-inbox__items[data-type="inbox"] .kt-inbox__item[data-type="inbox"]{
        flex-direction: column;
        align-items: flex-start;
        min-width: 100px;
    }
    
    .kt-inbox .kt-inbox__list .kt-inbox__items .kt-inbox__item .kt-inbox__info{
        width: 100%;
        margin: 0px !important;
    }
    
    .kt-inbox .kt-inbox__list .kt-inbox__items .kt-inbox__item .kt-inbox__info .kt-inbox__sender{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px !important;
    }
    
    .kt-inbox .kt-inbox__list .kt-inbox__items .kt-inbox__item.kt-inbox__item--unread .kt-inbox__datetime{
        width: 100%;
        text-align: left;
        margin: 0px !important;
    }
    
    .kt-inbox .kt-inbox__form{
        min-width: 100px;
    }
}

@media (max-width: 500px) {
    .kt-widget17__items{
        &.grid{
            grid-template-columns: 100%;
        }
    }     
}

@media (max-width: 460px) {
    .client_table-info{
        font-size: 0.8rem;
        width: 70px;
    
        &.action{           
            font-size: 0.8rem;
            width: 70px;            
        }
    
        &.status{
            font-size: 0.8rem;
            width: 90px;            
        }
    }

    .user_list-info{
        font-size: 0.8rem;        
    
        &.point{
            font-size: 0.8rem;          
        }
    
        &.role{
            font-size: 0.8rem;
        }
    
        &.status{
            width: 90px;
            font-size: 0.8rem;            
        }
    
        &.action{
            font-size: 0.8rem;            
        }
    }
}

@media (max-width: 450px) { 
    .point_add_user_checkbox-div{
        font-size: 0.9rem;
    }

    .kt-subheader .kt-subheader__main .kt-subheader__title.detail_good{
        display: none;        
    }

    .dropdown_body_changeLog{
        max-width: 300px;
    }
    
    .dropdown_row_changeLog{
        white-space: wrap;
    }
}

@media (max-width: 400px) { 
    .adduser-button{    
        font-size: 0.9rem;
        padding: 0.65rem 0.5rem;
    }

    .user_list-info{
        width: 100px;
    }

    .kt-datatable__pager{
        font-size: 0.8rem;
    }

    .kt-datatable.kt-datatable--default > .kt-datatable__pager > .kt-datatable__pager-nav > li > .kt-datatable__pager-link {
            font-size: 0.8rem;
    }
}

@media (max-width: 390px) {
    .client_table-info{
        width: 60px;
    }
}

@media (max-width: 350px) {
    .point_add_user_checkbox-div{
        font-size: 0.8rem;
    }
}

@media (max-width: 340px) {
    .user_list-info{
        width: 80px;
    
        &.role{
            width: 80px;            
        }
    }
}

@media (max-width: 330px){
    .kt-datatable.kt-datatable--default > .kt-datatable__pager{
        padding: 10px 5px 5px 5px;
    }
}

@media (max-width: 325px){
    .kt-aside_blackout {        
        width: 10%;
    }
}
