.neworder_container{
    height: calc(100vh - 55px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
    }

    &.goods{
        height: calc(100vh - 80px);     
        width: 100%;
    }

    &.categories{
        margin-right: 10px;
        width: 205px;
        min-width: 205px;
    }

    &.detail{
        padding: 10px !important;
        margin-right: 10px;
        min-width: 320px;
    }

    &.order_position_list{
        width: 480px;
        min-width: 480px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        padding: 0px;
        overflow: hidden;
    }
    
    &.goods_mobile{
        padding: 0px;
        width: 310px;
        display: flex;
    }
}

.neworder_good_main{
    display: flex;
    flex-direction: column;
    gap: 2px;
    min-width: 250px;
}

.neworder_good_head{
    width: 100%;
    height: 23px;
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f8fa;
    box-shadow: 0px 2px 0px #e0e0e4;
    color: #595d6e;
}

.new_order_switch{
    height: 20px !important;

    &::before{
        height: 20px !important;
    }

    &::after{
        height: 16px !important;
        line-height: 16px !important;
    }
}

.new_order_search_div{
    position: relative;
    top: 5px;
}

.new_order_search_container{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;
    border: 1px solid #d0d0d4;
    background-color: #f7f8fa;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 999;
}

.new_order_search_button{
    padding: 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
    width: 100%;
    min-height: 30px;
    border: none;
    outline: none;
    font-size: 0.8rem;
    background-color: #f1f1f5;
    transition: all 0.3s ease;

    &:hover{
        background-color: #e0e0e4;
    }

    &:active{
        background-color: #d0d0d4;
    }

    &.unactived{
        cursor: auto;
        background-color: #e0e0e4;
        color: #00000080;
    }
}

.neworder_good_button_container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.neworder_good_button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
    position: relative;
    width: 145px;
    height: 70px;
    border-radius: 4px !important;
    border: none;
    font-size: 0.8rem;
    background-color: #f1f1f5;
    box-shadow: 0px 2px 0px #e0e0e4;
    transition: all 0.3s ease;

    &:hover{
        background-color: #e0e0e4;
        box-shadow: 0px 2px 0px #d0d0d4;
    }

    &:active{
        background-color: #d0d0d4;
    }

    &.unactived{
        cursor: auto;
        background-color: #e0e0e4;
        box-shadow: 0px 2px 0px #e0e0e4;
        color: #00000080;
    }

    &.pizza{
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.neworder_good_button_text{
    width: 100%;
    max-height: 50%;
    overflow: hidden;
    font-size: 0.8rem;

    &.price{
        font-size: 0.9rem;
        font-weight: 600;
    }

    &.pizza{
        max-height: 30%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.neworder_container .kt-todo__nav .kt-nav .kt-nav__item--active{
    background-color: #f7f8fa;
}

.neworder_container .kt-todo__nav .kt-nav .kt-nav__item--active .kt-nav__link .kt-nav__link-text{
    color: #5d78ff; 
}

.neworder_pizza_modification_button_div{
    display: flex;
    width: 100%;
    font-size: 0.8rem;
    outline: 1px solid black;
    border-radius: 4px !important;
}

.neworder_pizza_modification_button{
    flex: 1;
    height: 17px;
    border: none;
    background-color: #f7f8fa;
    transition: all 0.3s ease;
    overflow: hidden;

    &:hover{
        background-color: rgb(227, 237, 255);
    }

    &:active{
        background-color: #d8deff;
    }

    &.actived{
        cursor: auto;
        background-color: #5d78ff;
        color: #f7f8fa;
    }

    &.unactived{
        opacity: 0.7;
    }
}

.good_hot_circle {
    background-color: #ff0000;
    opacity: 0.7;
    width: 5px;
    height: 5px;
    border-radius: 50% !important;
    position: absolute;
    margin-bottom: 60px;
    margin-left: 135px;
}

.good_vegan_circle {
    background-color: green;
    opacity: 0.7;
    width: 5px;
    height: 5px;
    border-radius: 50% !important;
    position: absolute;
    margin-bottom: 60px;
    margin-left: 135px;
}

.neworder_detail_body{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #595d6e;    
}

.select_detail_body{
    display: flex;
    gap: 5px;
    width: 100%;
}

.form-control{
    &.neworder{
        -moz-appearance: textfield;
        width: calc(100% - 38px);

        &::-webkit-inner-spin-button{
            display: none;
        }

        &::-webkit-outer-spin-button{
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-inner-spin-button{
          -webkit-appearance: none;
          margin: 0;
        }

        &.phone{
            width: calc(100% - 87px);
        }
    }

    &.search{
        padding-left: 5px;
        height: 20px;        
    }
}

.neworder_good_head_search_block{
    max-width: 200px;
    width: 50%;
}

.neworder_detail_information{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.promotionDescription{
        cursor: pointer;
        color: #536be6;
        transition: all 0.3s ease;
        align-items: start;
        flex-direction: column;

        &:hover{
            color: #485fd1;
        }
    }
}

.neworder_detail_deliverymethod_button{
    height: 2rem;
    width: 65px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 0.15rem 0.25rem;
    color: #595d6e;    
    transition: all 0.15s ease-in-out;
    background-color: #f1f1f5;
    box-shadow: 0px 1px 0px #e0e0e4;

    &:hover{
        background-color: #e0e0e4;
        box-shadow: 0px 1px 0px #d0d0d4;
    }

    &:active{
        background-color: #d0d0d4;
    }

    &.own{
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    &.delivery{        
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    &.actived{
        cursor: auto;
        color: #f7f8fa;
        background-color: #5d78ff;
        box-shadow: 0px 1px 0px #5871f1;
    }
}

.neworder_detail_block{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px dashed #d0d0d4;
    padding-top: 10px;
}

.neworder_comments_point_block{
    width: 300px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.neworder_comment_point{
    height: 2rem;
    min-width: 2.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    outline: none;
    padding: 5px;
    color: #646c9a;
    border-bottom: 1px dotted #c4e0f3;
    width: 100%;

    &:last-of-type{
        border: none;
    }
}

.neworder_detail_input_div{
    display: flex;
    align-items: center;
    gap: 10px;    
}

.neworder_detail_input_text{
    width: 27.5px;
}

.order_position_list_body{
    height: calc(100vh - 150px);
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
    }
}

.order_position_list_footer{
    padding: 10px;
    height: 105px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px -2px 0px #e0e0e4;
}

.position_list_column{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    border-bottom: 1px dashed #d0d0d4;
    padding-top: 5px;
    padding-bottom: 5px;
}

.position_list_row{
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 450px;
    padding-left: 10px;
    color: #595d6e;
    gap: 10px;

    &.head{
        height: 23px;
        background-color: #f7f8fa;
        box-shadow: 0px 2px 0px #e0e0e4;
    }

    &.totalPrice{
        padding-top: 5px;
        font-size: 1.2rem;
        padding-right: 10px;
        justify-content: space-between;
    }
}

.position_list_position{
    &.head_name{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: calc(45% - 20px);
    }
    
    &.container_name{
        display: flex;
        flex-direction: column;
        width: calc(45% - 20px);
    }

    .row_name{
        display: flex;
        gap: 10px;
    }

    &.name{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;         
    }

    &.count{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        width: calc(20% - 10px);
    }

    &.price{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(15% - 10px);
    }

    &.totalPrice{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(20% - 10px);
    }
}

.position_list_count_button{
    height: 1.5rem;
    width: 1.7rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: none;
    outline: none;
    padding: 0.15rem 0.25rem;
    background-color: #f1f1f5;
    color: #595d6e;    
    border-radius: 4px !important;
    align-self: center;
    transition: all 0.15s ease-in-out;

    &:hover{
        color: #5d78ff;
        background-color: #e3e3e9;
    }

    &:active{
        background-color: #d0d0d4;
    }
}

.new_order_input{
    display: block;
    height: 1.7rem;
    padding-left: 5px;
    color: #595d6e;
    background-color: #fff;
    background-clip: padding-box;
    outline: none;
    border: 1px solid #e2e5ec;
    border-radius: 4px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: textfield;

        &::-webkit-inner-spin-button{
            display: none;
        }

        &::-webkit-outer-spin-button{
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-inner-spin-button{
          -webkit-appearance: none;
          margin: 0;
        }
    
    &:focus{
        border-color: #9aabff;       
    }

    &.count{
        padding: 0px;
        width: 1.7rem;
        text-align: center;    
    }
}

.order_position_list_footer_buttons_div{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.order_position_list_footer_acide_buttons{
    display: flex;
    gap: 10px;
}

.order_position_list_footer_button{
    min-height: 2rem;
    min-width: 2.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 0.15rem 0.25rem;
    color: #595d6e;    
    border-radius: 4px !important;
    overflow: hidden;
    background-color: #f1f1f5;
    box-shadow: 0px 1px 0px #e0e0e4;
    transition: all 0.15s ease-in-out;

    &:hover{
        color: #5d78ff;
        background-color: #e0e0e4;
        box-shadow: 0px 2px 0px #d0d0d4;
    }

    &:active{
        background-color: #d0d0d4;
    }

    &.payment{
        &:hover{
            color: #595d6e;  
            background-color: #e0e0e4;
            box-shadow: 0px 2px 0px #d0d0d4;
        }
    
        &:active{
            background-color: #d0d0d4;
        }
    }

    &.actived{
        color: #f7f8fa;
        background-color: #5d78ff;
        box-shadow: 0px 2px 0px #5871f1;

        &:hover{
            color: #f7f8fa;
            background-color: #5d78ff;
            box-shadow: 0px 2px 0px #5871f1;
        }
    
        &:active{
            color: #f7f8fa;
            background-color: #5d78ff;
        }
    }       

    &.save{
        padding: 0.15rem 0.75rem;
        color: #f7f8fa;
        background-color: #5d78ff;
        box-shadow: 0px 2px 0px #5871f1;

        &:hover{
            background-color: #5871f1;
            box-shadow: 0px 2px 0px #536be6;
        }
    
        &:active{
            background-color: #536be6;
        }
    }
}

.detail_order_changecash_container{
    display: grid; 
    grid-template-rows: repeat(3, 1fr); 
    grid-gap: 5px; 
    border: none;
    padding: 5px 0.25rem;
    color: #595d6e;    
    border-radius: 4px !important;
    background-color: #f7f8fa86;
    outline: 1px solid #e0e0e4;
}

.detail_order_changecash_buttons_div{
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 5px; 
    font-size: 0.9rem;
}

.order_position_footer_dropdown_container{
    position: relative;   
    &.description{
        width: 100%;   
    }
}

.order_position_footer_dropdown{
    display: flex;
    flex-basis: content;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f7f8fa;
    color: #595d6e;
    border: 1px solid #e2e5ec;
    border-radius: 4px !important;
    max-height: 390px;
    transform: translateY(calc(-100% - 10px));
    z-index: 100; 
    overflow-x: hidden;
    overflow-y: auto;

    &.description{
        padding: 5px;
        transform: translateY(0px);        
    }
}

.order_position_footer_dropdown_button{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 0.25rem;
    color: #595d6e;    
    background-color: #f1f1f5;
    transition: all 0.15s ease-in-out;   
    
    &:hover{
        background-color: #e0e0e4;
    }

    &:active{
        background-color: #d0d0d4;
    }

    &.actived{
        background-color: #5d78ff;
        color: #f7f8fa;
    }

}

.order_position_list_inf_button{
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: none;
    outline: none;
    padding: 0.15rem 0.25rem;
    color: #595d6e;    
    border-radius: 4px !important;
    align-self: center;
    transition: all 0.15s ease-in-out;
    background-color: #00000000;

    &.info_point{
        width: 2rem;
        height: 2rem;
    }

    &:hover{
        color: #5d78ff;
        background-color: #f1f1f9;
    }
    
    &:active{
        background-color: #e3e3e9;
    }
}

.popup_additives{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 5px;
    background-color: #fbfbfc;
    color: #595d6e;
    border: 1px solid #e0e0e4;
    border-radius: 4px !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.popup_additives_category{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.popup_additives_buttons_div{
    display: grid; 
    grid-template-columns: repeat(4, 1fr); 
    grid-gap: 5px; 
}

.popup_additives_button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
    position: relative;
    width: 95px;
    height: 45px;
    border-radius: 4px !important;
    border: none;
    font-size: 0.7rem;
    background-color: #f1f1f5;
    box-shadow: 0px 1px 0px #e0e0e4;
    transition: all 0.3s ease;

    &:hover{
        background-color: #e0e0e4;
        box-shadow: 0px 1px 0px #d0d0d4;
    }

    &:active{
        background-color: #d0d0d4;
    }

    &.unactived{
        cursor: auto;
        background-color: #e0e0e4;
        box-shadow: 0px 1px 0px #e0e0e4;
        color: #00000080;
    }
}

.popup_additives_hot_circle{
    background-color: #ff0000;
    opacity: 0.7;
    width: 5px;
    height: 5px;
    border-radius: 50% !important;
    position: absolute;
    margin-bottom: 35px;
    margin-left: 85px;
}

.popup_additives_vegan_circle {
    background-color: green;
    opacity: 0.7;
    width: 5px;
    height: 5px;
    border-radius: 50% !important;
    position: absolute;
    margin-bottom: 35px;
    margin-left: 85px;
}

.tab_mobile-buttons_container{
    display: flex;
    gap: 0px;
    padding: 0px;
    background-color: #f2f3f8;
    width: 310px;
    border-bottom: 1px solid #e0e0e4;
}

.tab_mobile-button{
    padding: 1px 10px;
    min-width: 65px;
    font-size: 1rem;
    outline: none;
    border: none;
    background-color: #ffffff;
    color: #000;
    transition: all 0.4s ease;

    &:hover{
        background-color: #6c85ff;
        color: #ffffff;
    }

    &:active{
        background-color: #5d78ff;
        color: #ffffff;
    }

    &.active{
        background-color: #5d78ff;
        color: #ffffff;
    }

    &:first-of-type{
        border-top-left-radius: 4px;
    }

    &:last-of-type{
        border-top-right-radius: 4px;
    }
}

.tab_mobile-button_total-price{
    position: absolute;
    margin-top: -22px;
    margin-left: 30px;
    padding: 0px 3px;
    border-radius: 10px;
    font-size: 0.9rem;
    color: #fff !important;
    background-color: #f35050;
}

.kt-grid.kt-grid--ver-desktop.kt-grid--desktop.neworder{
    display: flex;
    flex-direction: row;        
}